import React, { useEffect, useRef } from "react"
import { navigate } from "gatsby"
import { useLocation } from "@reach/router"

export default function PageTransition({ oldPathname, children }) {
  const { pathname } = useLocation()
  const overlayWrapperRef = useRef(null)

  useEffect(() => {
    const overlayWrapper = overlayWrapperRef.current

    if (!overlayWrapper) return

    function handlePageExit(event, path) {
      event.preventDefault()

      if (pathname === path) return
      
      overlayWrapper.style.zIndex = 30
      overlayWrapper.style.opacity = 1

      overlayWrapper.classList.remove("page-load")
      overlayWrapper.classList.add("page-exit")
      setTimeout(() => { document.body.classList.remove('menu-open'); document.body.classList.remove('menu--preview');}, 500);

      setTimeout(() => navigate(path), 850)
    }

    function handlePageLoad() {
      window.scrollTo(0, 0)
      overlayWrapper.classList.remove("page-exit")
      overlayWrapper.classList.add("page-load")
    }

    if (oldPathname === null) {
      overlayWrapper.style.transform = `translateY(100%)`
      overlayWrapper.style.zIndex = -1
      overlayWrapper.style.opacity = 0
    } else {
      overlayWrapper.style.transform = ``
      overlayWrapper.style.zIndex = 30
      overlayWrapper.style.opacity = 1
      handlePageLoad()
    }

    const links = document.querySelectorAll("a")

    links.forEach(link => {
      if (link.hasAttribute("target")) return
      const path = link.getAttribute("href")
      link.addEventListener("click", e => handlePageExit(e, path))
    })

    return () => {
      links.forEach(link => {
        const path = link.getAttribute("href")
        link.removeEventListener("click", e => handlePageExit(e, path))
      })
    }

  }, [oldPathname, pathname])

  return (
    <>
      {children}
      <div ref={overlayWrapperRef} className='overlay-panel' />
    </>
  )
}
